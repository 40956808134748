const getAgreementTypeTranslationFromObject = (t, data) => {
    if ('electricitySupplier' in data) {
        return t('AgreementType.electricitySupplier');
    } else if ('networkOperator' in data) {
        return t('AgreementType.networkOperator');
    } else if ('districtHeating' in data) {
        return t('AgreementType.districtHeating');
    } else {
        return '';
    }
};

const getAgreementTypeTranslation = (t, kind) => {
    const kinds = {
        ElectricitySupplier: t('AgreementType.electricitySupplier'),
        NetworkOperator: t('AgreementType.networkOperator'),
        DistrictHeating: t('AgreementType.districtHeating'),
    };

    return kinds[kind];
};

const getDirectionTranslation = (t, direction) => {
    const directions = {
        Production: t('Direction.production'),
    };
    return directions[direction];
};

const getHousingTranslation = (t, type) => {
    const houseTypes = {
        apartment: t('HouseType.apartment'),
        house: t('HouseType.house'),
        villa: t('HouseType.villa'),
        weekender: t('HouseType.weekender'),
    };
    return houseTypes[type];
};

const getInvoiceTypeTranslation = (t, type) => {
    const invoiceTypes = {
        Electricity: t('InvoiceType.electricity'),
        ElectricityNet: t('InvoiceType.electricityNet'),
        Net: t('InvoiceType.net'),
        DistrictHeating: t('InvoiceType.districtHeating'),
    };
    return invoiceTypes[type];
};

const getProductTranslationFromId = (t, id) => {
    const products = {
        2: t('Product.variableElectricityCost'),
        3: t('Product.jamtprisetWithLocalDiscount'),
        31: t('Product.jamtpriset'),
        150: t('Product.variableElectricityCostWidthLocalDiscount'),
        435: t('Product.variableElectricityCostWithCeiling'),
        450: t('Product.variableElectricityCostWithCeiling'),
    };
    return products[id];
};

const getProductTextTranslation = (t, product) => {
    const productTexts = {
        variableElectricityCostWithCeiling: t(
            'ProductText.variableElectricityCostWithCeiling'
        ),
        jamtpriset: t('ProductText.jamtpriset'),
        variableElectricityCost: t('ProductText.variableElectricityCost'),
    };

    return productTexts[product];
};

export {
    getAgreementTypeTranslationFromObject,
    getAgreementTypeTranslation,
    getDirectionTranslation,
    getHousingTranslation,
    getInvoiceTypeTranslation,
    getProductTranslationFromId,
    getProductTextTranslation,
};
