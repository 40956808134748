import React from 'react';
import { setCookie } from 'cookies-next';
import { useTranslation } from 'next-i18next';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import classNames from 'classnames';
import debounce from 'debounce';

import styles from './FormAddressInputField.module.scss';

const FormAddressInputField = ({
    setAddressData,
    placeholder = '',
    label = null,
}) => {
    const { t } = useTranslation();
    placeholder = !!placeholder ? placeholder : t('CardSignup.enterAddress');

    const handleChange = (event) => {
        if (!event.value) {
            return;
        }
        setAddressData(event.value);
    };

    const loadOptions = (inputValue, callback) => {
        if (!inputValue || inputValue.length < 3) return;
        axios({
            method: 'GET',
            url: `/api/address-suggestions/?q=${encodeURIComponent(inputValue)}`,
            headers: {
                Accept: 'application/json',
            },
        })
            .then((response) => {
                const options = response.data.result.map((item) => {
                    const value = JSON.stringify({
                        countyCode: item.countyCode,
                        municipalityCode: item.municipalityCode,
                        street: item.street,
                        streetNumber:
                            item.streetNumber +
                            (item.letter ? item.letter : ''),
                        letter: item.letter,
                        postCode: item.postCode,
                        city: item.city,
                    });

                    const letter = item.letter ?? '';
                    const streetNumber = item.streetNumber
                        ? ` ${item.streetNumber}${letter}`
                        : '';

                    return {
                        value: value,
                        label: `${item.street}${streetNumber}, ${item.postCode} ${item.city}`,
                    };
                });

                setCookie('back-url', window.location.href, {
                    maxAge: 60 * 60,
                });

                callback(options);
            })
            .catch(() => {
                callback([]);
            });
    };

    const debounceLoadOptions = debounce(loadOptions, 1000);

    const selectStyles = {
        container: () => ({
            position: 'relative',
            width: '100%',
        }),
        control: (state) => ({
            display: 'flex',
            padding: label ? '13.5px 14px' : '20px 14px',
            backgroundColor: '#fff',
            borderRadius: '8px',
            border: '1px solid #005b75',
            borderBottom: state.menuIsOpen
                ? '1px solid transparent'
                : '1px solid #005b75',
            borderBottomLeftRadius: state.menuIsOpen ? '0' : '8px',
            borderBottomRightRadius: state.menuIsOpen ? '0' : '8px',
        }),
        placeholder: (provided) => ({
            ...provided,
            fontFamily: 'Inter',
            fontSize: '1.6rem',
            letterSpacing: '0.01rem',
            color: '#757575',
        }),
        input: (provided) => ({
            ...provided,
            fontFamily: 'Inter',
            fontSize: '1.6rem',
            letterSpacing: '0.01rem',
            color: '#003742',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontFamily: 'Inter',
            fontSize: '1.6rem',
            lineHeight: '2rem',
            letterSpacing: '0.01rem',
            color: '#005b75',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        dropdownIndicator: () => ({
            color: '#005b75',
            display: 'none',
        }),
        noOptionsMessage: (provided) => ({
            ...provided,
            paddingBottom: '14px',
            fontFamily: 'Inter',
            fontSize: '1.4rem',
            lineHeight: '2rem',
            letterSpacing: '0.01rem',
        }),
        loadingMessage: (provided) => ({
            ...provided,
            paddingBottom: '14px',
            fontFamily: 'Inter',
            fontSize: '1.4rem',
            lineHeight: '2rem',
            letterSpacing: '0.01rem',
        }),
        menu: (provided) => ({
            ...provided,
            marginTop: '-20px',
            paddingTop: '20px',
            fontFamily: 'Inter',
            fontSize: '1.4rem',
            letterSpacing: '0.01rem',
            borderRight: '1px solid #005b75',
            borderBottom: '1px solid #005b75',
            borderLeft: '1px solid #005b75',
            borderRadius: '0 0 8px 8px',
            boxShadow: 'none',
            overflow: 'hidden',
        }),
        menuList: () => ({
            color: '#005b75',
        }),
        option: (provided, state) => ({
            ...provided,
            padding: '14px 14px',
            fontFamily: 'Inter',
            fontSize: '1.4rem',
            letterSpacing: '0.1rem',
            backgroundColor: state.isSelected
                ? '#005b75'
                : state.isFocused
                  ? '#f1f5f3'
                  : '#fff',
            color: state.isSelected
                ? '#fff'
                : state.isFocused
                  ? '#005b75'
                  : '#005b75',
            ':hover': {
                color: state.isSelected ? '#fff' : '#005b75',
                backgroundColor: state.isSelected ? '#005b75' : '#f1f5f3',
            },
        }),
    };

    return (
        <span
            className={classNames(styles['FormAddressInputField'], {
                [styles['FormAddressInputField--HasLabel']]: label,
            })}>
            <span className={styles['FormAddressInputField__Label']}>
                {label}
            </span>
            <AsyncSelect
                styles={selectStyles}
                loadOptions={debounceLoadOptions}
                id={'address-input'}
                instanceId={'address-input'}
                onChange={handleChange}
                aria-label={placeholder}
                placeholder={placeholder}
                loadingMessage={() => t('CardSignup.loading')}
                noOptionsMessage={(e) => {
                    if (e.inputValue) {
                        return t('CardSignup.noResult');
                    }
                    return t('CardSignup.waiting');
                }}
            />
        </span>
    );
};

FormAddressInputField.propTypes = {};

export default FormAddressInputField;
