import { factory as linkFactory } from '../../data/link';

export default {
    title: 'Vill du teckna nytt?',
    text: 'Ange din adress och välj fastighetstyp nedan för att teckna ett elavtal.',
    selectButtons: [
        {
            icon: 'apartment',
            text: 'Lägenhet',
        },
        {
            icon: 'house',
            text: 'Villa',
        },
        {
            icon: 'weekender',
            text: 'Fritidshus',
        },
    ],
    button: {
        type: 'primary',
        text: 'Nästa',
        icon: 'arrow',
        iconSize: 'xsmall',
        iconDirection: '',
        disabled: false,
    },
    dialog: {
        richText:
            '<h3>Är det du som står på nuvarande elavtal på {0}?</h3><p>För att vi ska kunna ge dig rätt information gällande ditt elavtal behöver vi ställa några frågor till dig. Du hittar informationen om vem som står på elavtalet på fakturan.</p>',
        buttons: [
            {
                type: 'primary',
                text: 'Ja',
                iconDirection: '',
                disabled: false,
            },
            {
                type: 'primary',
                text: 'Nej',
                iconDirection: '',
                disabled: false,
            },
        ],
        bottomContent:
            '<p>Ska du teckna elavtal för någon annan? <a href="#">Kontakta oss</a>.</p>',
    },
};
