import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { setCookie, getCookie } from 'cookies-next';
import { useTranslation } from 'next-i18next';
import { getHousingTranslation } from '../../utils/translationUtils';
import { ucFirst } from '../../utils/caseconverters';

// import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icon';
import Button from '../Button';
import FormAddressInputField from '../FormAddressInputField';
import styles from './CardSignup.module.scss';

import data from './CardSignup.data';

const CardSignup = ({
    title = '',
    text = '',
    hasNextButton = true,
    modifiers = [],
    setExternalHousing = () => {},
    setExternalAddressData = () => {},
}) => {
    // These are hardcoded by the data-file for now, in future
    // we might make some of them dynamic. But since a lot of it
    // will break the integration if changed, it's probably
    // not a great idea to let editors change the values
    const { t } = useTranslation();
    const selectButtons = data.selectButtons;
    const button = data.button;

    const [activeIndex, setActive] = useState(null);
    const [addressData, setAddressData] = useState('');
    const [apiDown, setApiDown] = useState(false);
    const housingList = ['apartment', 'house', 'weekender'];

    useEffect(() => {
        axios({
            method: 'GET',
            url: `/api/address-suggestions/?q=hej`,
            headers: {
                Accept: 'application/json',
            },
        }).catch(() => {
            setApiDown(true);
        });
    }, []);

    useEffect(() => {
        if (!addressData) {
            return;
        }

        const addrData = JSON.parse(addressData);
        setExternalAddressData(addrData);
    }, [addressData]);

    useEffect(() => {
        if (activeIndex === null) {
            return;
        }

        const housing = housingList[activeIndex];

        setExternalHousing(housing);
    }, [activeIndex]);

    const goToForm = () => {
        const housing = housingList[activeIndex];
        const postCode = JSON.parse(addressData)?.postCode;
        const adrData = JSON.parse(addressData);

        adrData.housing = housing;

        setCookie('address-data', adrData, { maxAge: 60 * 60 });

        setCookie(
            'data-layer',
            {
                houseType: getHousingTranslation(t, housing),
                zipCode: postCode,
                moving: false,
                startEvent: 'Regular start',
            },
            { maxAge: 60 * 60 }
        );

        try {
            let dataLayer = getCookie('data-layer');
            dataLayer = JSON.parse(dataLayer);

            window._mtm = window._mtm || [];
            window._mtm.push({
                zipCode: dataLayer.zipCode,
                houseType: dataLayer.houseType,
                moving: data.moving,
                startEvent: data.startEvent,
                formName: 'Teckna avtal Privat - starta',
                event: 'residentialAgreementStart',
            });
        } catch (err) {
            console.log(err.message);
        }

        window.location.href = '/avtal';
    };

    const classes = classNames(
        styles['CardSignup'],
        modifiers.map(
            (modifier) => styles['CardSignup--' + ucFirst(modifier)] + ' '
        )
    );

    if (apiDown) {
        return (
            <article className={classes}>
                <h2 className={styles['CardSignup__Title']}>
                    {t('CardSignup.apiDownTitle')}
                </h2>
                <p className={styles['CardSignup__Text']}>
                    {t('CardSignup.apiDownText')}
                </p>
            </article>
        );
    }

    return (
        <article className={classes}>
            <h2 className={styles['CardSignup__Title']}>{title}</h2>
            <p className={styles['CardSignup__Text']}>{text}</p>
            <div className={styles['CardSignup__Grid']}>
                <div className={styles['CardSignup__InputField']}>
                    <FormAddressInputField {...{ setAddressData }} />
                </div>
                <div className={styles['CardSignup__ButtonContainer']}>
                    {selectButtons.map((button, index) => (
                        <SelectButton
                            key={index}
                            isActive={activeIndex === index}
                            onClick={() =>
                                setActive(activeIndex === index ? -1 : index)
                            }
                            {...button}
                        />
                    ))}
                </div>

                {hasNextButton && (
                    <div className={styles['CardSignup__NextButton']}>
                        <Button
                            {...button}
                            disabled={
                                addressData === '' || activeIndex === null
                            }
                            onClick={goToForm}
                        />
                    </div>
                )}
            </div>
        </article>
    );
};

CardSignup.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    input: PropTypes.shape({
        label: PropTypes.string,
        helpText: PropTypes.string,
        required: PropTypes.bool,
        id: PropTypes.string,
        component: PropTypes.string,
        name: PropTypes.string,
    }),
    selectButtons: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            text: PropTypes.string,
        })
    ),
    button: PropTypes.shape({
        type: PropTypes.string,
        buttonType: PropTypes.string,
        link: PropTypes.object,
        icon: PropTypes.string,
        iconSize: PropTypes.string,
        iconDirection: PropTypes.string,
        disabled: PropTypes.bool,
    }),
    dialog: PropTypes.shape({
        title: PropTypes.string,
        richText: PropTypes.string,
        buttons: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
                buttonType: PropTypes.string,
                link: PropTypes.object,
                icon: PropTypes.string,
                iconSize: PropTypes.string,
                iconDirection: PropTypes.string,
                disabled: PropTypes.bool,
            })
        ),
    }),
    hasNextButton: PropTypes.bool,
    // setExternalAddressData: PropTypes.func,
    setExternalHousing: PropTypes.func,
};

const SelectButton = ({ isActive = false, onClick, icon = '', text = '' }) => {
    const classes = classNames(styles['CardSignup__Button'], {
        [styles['CardSignup__Button--Active']]: isActive,
    });

    return (
        <button className={classes} onClick={onClick}>
            <span className={styles['CardSignup__Icon']}>
                <Icon
                    type={icon}
                    dimensions={{ width: '31px', height: '38px' }}
                />
            </span>
            {text}
        </button>
    );
};

SelectButton.propTypes = {
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    text: PropTypes.string,
};

const DialogContent = ({
    title = '',
    richText = '',
    bottomContent = '',
    buttons = [],
}) => {
    return (
        <>
            <h2 className={dialogStyles['Dialog__Title']}>{title}</h2>
            <div
                className={dialogStyles['Dialog__RichText']}
                dangerouslySetInnerHTML={{
                    __html: richText,
                }}
            />
            <div className={dialogStyles['Dialog__ButtonContainer']}>
                {buttons.map((button, index) => (
                    <div className={dialogStyles['Dialog__Button']}>
                        <Button key={index} {...button} />
                    </div>
                ))}
            </div>
            <div
                className={dialogStyles['Dialog__BottomContent']}
                dangerouslySetInnerHTML={{ __html: bottomContent }}
            />
        </>
    );
};

DialogContent.propTypes = {
    title: PropTypes.string,
    richText: PropTypes.string,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
        })
    ),
    bottomContent: PropTypes.string,
};

export default CardSignup;
