import {factoryGenerator} from "./factory";

const baseStructure = {
    title: 'Link text',
    href: 'https://picsum.photos/id/29//2480/1653',
    target: '_self',
    id: ""
}

export const factory = factoryGenerator('Link', baseStructure);

export default baseStructure;
